<template>
  <div class="flex flex-center overflow-visible relative-position">
    <transition
      appear
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <circle-text-lines
        :circle-type-options="{
          activeColors: true,
          direction: 1,
          fill: fillColor,
          forceHeight: true,
          forceWidth: true,
          radius: 150,
          stroke: true,
          strokeWidth: $q.screen.gt.sm ? 2 : 1,
        }"
        :text="header"
        tag="h3"
        :text-class="`${textClass} text-letterspace`"
      />
    </transition>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import CircleTextLines from "./CircleTextLines.vue";

defineOptions({ name: "CircleTextHeader" });

const props = defineProps({
  header: { type: String, default: "Hello" },
  fill: { type: String, default: "dark" },
  textClass: { type: String, default: "text-subtitle1" },
});

const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);
</script>
