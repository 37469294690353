<template>
  <q-no-ssr>
    <component
      v-if="text !== ''"
      :class="textClass"
      class="circle-text-lines text-center"
      :is="tag"
      ref="textElement"
    >
      <span style="hyphens: auto">
        {{ text }}
      </span>
    </component>
    <template v-slot:placeholder>
      <component
        :class="textClass"
        class="circle-text-lines text-center"
        :is="tag"
        ><span style="hyphens: auto">{{ text }}</span></component
      >
    </template>
  </q-no-ssr>
</template>

<script>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useSSRContext,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { uid, throttle } from "quasar";
import { useColorStore } from "src/stores/color";
import { useLayoutStore } from "src/stores/layout";
import { useCircleTextLines } from "src/composables/circleText/useCircleTextLines";

export default defineComponent({
  name: "CircleTextLines",
  emits: ["hasWidth", "showing"],
  props: {
    circleTypeOptions: {
      type: Object,
      default: () => ({
        activeColors: false,
        direction: 1,
        fill: "dark",
        invertColor: false,
        forceHeight: true,
        forceWidth: true,
        stroke: true,
        strokeColor: "primary",
        strokeWidth: 7,
      }),
    },
    invertColor: { type: Boolean, default: false },
    reduceLongTextRadius: { type: Boolean, default: true },
    tag: { type: [String, Object], default: "h2" },
    text: { type: String, default: "" },
    textClass: { type: String, default: null },
  },
  setup(props, context) {
    const colorStore = useColorStore();
    const { palette } = storeToRefs(colorStore);
    const layoutStore = useLayoutStore();
    const { sizeDetails } = storeToRefs(layoutStore);
    const ssrContext = process.env.SERVER ? useSSRContext() : null;
    const showing = ref(false);
    const textElement = ref(null);
    const computedText = computed(() => props.text);
    const className = computed(() => `circleTextLines-${uid()}`);
    const options = computed(() => props.circleTypeOptions);
    const computedPropFill = computed(() => props.circleTypeOptions.fill);
    const computedRadiusValue = computed(() => {
      if (textElement.value) {
        const { clientWidth } = textElement.value;
        let radius = props.circleTypeOptions.radius
          ? props.circleTypeOptions.radius
          : clientWidth / 2;
        if (
          props.reduceLongTextRadius &&
          props.text?.length &&
          props.text.length >= 9
        ) {
          radius = radius * 2;
        }
        return radius;
      } else {
        return void 0;
      }
    });
    const computedOptions = computed(() => {
      return {
        ...options.value,
      };
    });
    const { animateRadiusChange, redrawCircleTextLines } = useCircleTextLines(
      textElement,
      className,
      {
        ...computedOptions.value,
        radius: computedRadiusValue.value,
        fill: computedPropFill.value,
        invertColor: props.invertColor,
      },
      ssrContext !== null,
      computedText,
      computedRadiusValue
    );
    const emitWidth = () => {
      const width = textElement.value.clientWidth;
      context.emit("hasWidth", width);
    };
    const redraw = () => {
      if (!textElement.value) return;
      nextTick(() => {
        if (!textElement.value) return;
        redrawCircleTextLines(computedOptions.value, computedRadiusValue.value);
        emitWidth();
      });
    };
    watch(computedText, () => {
      redraw();
    });
    watch(
      palette,
      () => {
        redraw();
      },
      { deep: true }
    );
    watch(
      sizeDetails,
      () => {
        redraw();
      },
      { deep: true }
    );
    onMounted(() => {
      if (!textElement.value) return;
      redraw();
    });
    context.expose({ redraw });
    return {
      showing,
      textElement,
      redraw,
    };
  },
  created() {
    this.redraw = throttle(this.redraw, 2000);
  },
});
</script>
