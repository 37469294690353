import { uid } from "quasar";
import { nextTick, ref, watch } from "vue";
let CircleType = null;

export function useCircleTextLines(
  elementRef,
  className,
  circleTypeOptions,
  isServer,
  textRef,
  updateRadius
) {
  const isSSR = import.meta.env.SSR;
  const initialTextContent = ref(null);
  const uniqueClassName = `circleLine-${uid()}`;
  const lines = ref([]);
  const range = !isSSR ? document.createRange() : void 0;

  const loadCircleType = async () => {
    if (!CircleType) {
      const module = await import("../../utils/circleText");
      CircleType = module.default;
    }
  };

  const arrayFrom = (arrayLike) => Array.prototype.slice.call(arrayLike);

  const collapseWhiteSpace = (value) => {
    if (typeof value !== "string") {
      return "";
    }
    return value.trim().replace(/\s+/g, " ");
  };

  const drawRectBoxes = (clientRects, options, updateRadius) => {
    const element = elementRef.value;
    if (element.firstChild) element.removeChild(element.firstChild);

    arrayFrom(document.querySelectorAll(`.${className.value}`)).forEach(
      (node) => {
        node.remove();
      }
    );

    arrayFrom(clientRects).forEach((rect, index) => {
      const box = document.createElement("div");
      box.classList.add(className.value, uniqueClassName);
      box.textContent = lines.value[index];
      box.style.top = rect.y + "px";
      box.style.height = rect.height + "px";
      element.appendChild(box);

      const circleTypeInstance = new CircleType(box, null, circleTypeOptions)
        .dir(circleTypeOptions.direction)
        .forceWidth(circleTypeOptions.forceWidth)
        .forceHeight(circleTypeOptions.forceHeight);
      if (updateRadius) {
        circleTypeInstance.radius(updateRadius);
      } else if (circleTypeOptions.radius) {
        circleTypeInstance.radius(circleTypeOptions.radius);
      }
    });
  };

  const updateLinesAndDraw = async (elem, options, updateRadius) => {
    const textContent = collapseWhiteSpace(textRef.value);
    elem.textContent = textContent;
    const lineCharacters = [];
    for (let i = 0; i < textContent.length; i++) {
      range.setStart(elem.firstChild, 0);
      range.setEnd(elem.firstChild, i + 1);
      const lineIndex = range.getClientRects().length - 1;
      if (!lineCharacters[lineIndex]) {
        lineCharacters[lineIndex] = [];
      }
      lineCharacters[lineIndex].push(textContent.charAt(i));
    }

    lines.value = lineCharacters.map((characters) => {
      const chars = characters.join("");
      return chars.trim().replace(/\s+/g, " ");
    });

    drawRectBoxes(range.getClientRects(), options, updateRadius);
  };

  const redrawCircleTextLines = async (options, updateRadius) => {
    if (!elementRef.value) return;
    await nextTick();
    await loadCircleType();
    updateLinesAndDraw(elementRef.value, options, updateRadius);
  };

  watch(textRef, async () => {
    if (!elementRef.value) return;
    initialTextContent.value = textRef.value;
    await redrawCircleTextLines(circleTypeOptions, updateRadius);
  });

  return {
    redrawCircleTextLines,
  };
}
